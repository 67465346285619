import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const toastVariants = {
  initial: { opacity: 0, y: 50, scale: 0.3 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

const Toast = ({ id, message, type, onRemove }) => {
  const getToastStyles = () => {
    switch (type) {
      case 'success':
        return 'bg-primary-3 border-primary-4 text-neutral-9';
      case 'error':
        return 'bg-red-50 border-red-500 text-red-700';
      case 'warning':
        return 'bg-yellow-50 border-yellow-500 text-yellow-700';
      case 'info':
        return 'bg-blue-50 border-blue-500 text-blue-700';
      default:
        return 'bg-gray-50 border-gray-500 text-gray-700';
    }
  };

  const getToastHeader = () => {
    switch (type) {
      case 'success':
        return 'Success';
      case 'error':
        return 'Error';
      case 'warning':
        return 'Warning';
      case 'info':
        return 'Information';
      default:
        return 'Notification';
    }
  };

  return (
    <motion.div
      layout
      variants={toastVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className={`min-w-[300px] pb-4 rounded-lg shadow-lg overflow-hidden ${getToastStyles()} border-l-4`}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <div className="flex items-center justify-between px-4 pt-2">
        <motion.h3
          className="text-sm font-bold"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
        >
          {getToastHeader()}
        </motion.h3>
        <motion.button
          onClick={() => onRemove(id)}
          className="p-1 transition-colors rounded-full hover:bg-black hover:bg-opacity-10"
          whileHover={{ rotate: 90 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            className="w-4 h-4"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </motion.button>
      </div>
      <div className="px-4 pb-2">
        <motion.p
          className="text-sm"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
        >
          {message}
        </motion.p>
      </div>
    </motion.div>
  );
};

Toast.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info', 'default'])
    .isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Toast;
