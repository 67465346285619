import React, { createContext, useReducer, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import Toast from '../components/Toast';
import PropTypes from 'prop-types';

export const ToastContext = createContext();

const ADD_TOAST = 'ADD_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';

const toastReducer = (state, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return [...state, action.toast];
    case REMOVE_TOAST:
      return state.filter((toast) => toast.id !== action.id);
    default:
      return state;
  }
};

export const ToastProvider = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, []);

  const addToast = useCallback((message, type = 'default', duration = 5000) => {
    const id = Math.random().toString(36).substr(2, 9);
    dispatch({
      type: ADD_TOAST,
      toast: { id, message, type, duration },
    });

    setTimeout(() => {
      dispatch({ type: REMOVE_TOAST, id });
    }, duration);
  }, []);

  const removeToast = useCallback((id) => {
    dispatch({ type: REMOVE_TOAST, id });
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <div className="fixed z-[60] flex flex-col gap-2 bottom-6 right-4">
        <AnimatePresence>
          {toasts.map((toast) => (
            <Toast key={toast.id} {...toast} onRemove={removeToast} />
          ))}
        </AnimatePresence>
      </div>
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};
