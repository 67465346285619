const React = require('react');

import { CalculationMethod, Madhab, PrayerTimes } from 'adhan';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const prayerTimeSlugs = [
  { slug: 'fajr', label: 'Fajr' },
  { slug: 'sunrise', label: 'Sunrise' },
  { slug: 'dhuhr', label: 'Dhuhr' },
  { slug: 'asr', label: 'Asr' },
  { slug: 'sunset', label: 'Sunset' },
  { slug: 'maghrib', label: 'Maghrib' },
  { slug: 'isha', label: 'Isha' },
];

export function getPrayerTime(coordinates, method) {
  const params = CalculationMethod.UmmAlQura();
  params.madhab = method || Madhab.Hanafi;
  const date = new Date();
  const prayerTimes = new PrayerTimes(coordinates, date, params);
  return prayerTimes;
}

export const checkLocation = async () => {
  const response = await fetch(
    'https://staging-data.gtaf.org/api/proxy/location/',
  );
  const data = await response.json();
  const location = {
    latitude: data.latitude,
    longitude: data.longitude,
    city: data.city,
    country: data.country_name,
    country_code: data.country_code,
    currency_code: data.currency?.code,
  };
  return location;
};

export const extractFileName = (str) => {
  const regex = /\/(.*?)\./;
  const match = str?.match(regex);

  return match ? match[1] : '';
};

export function getHeadings(content) {
  const headings = [];

  const h2Regex = /<h2.*?>(.*?)<\/h2>/g;

  let match;
  while ((match = h2Regex.exec(content)) !== null) {
    if (match[0].includes('id=')) {
      let id = match[0].match(/id="(.*?)"/)?.[1];
      const div = document.createElement('div');
      div.innerHTML = id;
      id = div.textContent;

      const titleDiv = document.createElement('div');
      titleDiv.innerHTML = match[1];
      const title = titleDiv.textContent;

      headings.push({
        hashLink: id,
        title,
      });
    }
  }

  return headings;
}

export const formatStringWithDoubleLineBreaks = (text) => {
  const paragraphs = text.split(/\n\s*\n/);
  return paragraphs.map((paragraph, index) => {
    const lines = paragraph.split('\n');
    return (
      <React.Fragment key={index}>
        {lines.map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            {lineIndex < lines.length - 1 && <br />}{' '}
          </React.Fragment>
        ))}
        {index < paragraphs.length - 1 && <p>&nbsp;</p>}{' '}
      </React.Fragment>
    );
  });
};

export const getIconName = (iconName) => {
  return iconName
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const transformArabicText = (text) => {
  return text
    .replace('\u061f', '\u2E2E') //arabic question mark
    .replace('\u06cc', '\u064A') //arabic small ya
    .replace('\u06a9', '\u0643'); //arabic small kaf
};

const getValueByPath = (obj, path) => {
  const keys = Array.isArray(path) ? path : path.split('.');
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj,
  );
};

export const sortByCustomOrder = (
  items,
  orderPath = 'order',
  defaultOrder = 999,
) => {
  return [...items].sort((a, b) => {
    const orderA = getValueByPath(a, orderPath) ?? defaultOrder;
    const orderB = getValueByPath(b, orderPath) ?? defaultOrder;

    if (orderA === orderB) {
      return 0;
    }

    return orderA - orderB;
  });
};

export const wrapArabicText = (text) => {
  const arabicRegex = /([\u0600-\u06FF]+)/g;
  return text
    .split(arabicRegex)
    .map((part) => {
      if (arabicRegex.test(part)) {
        return `<span class="font-arabic">${part}</span>`;
      }
      return `<span class="font-regular">${part}</span>`;
    })
    .join('');
};
